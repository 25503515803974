import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-f8d62cdb2b/2/root/cache/yarn/next-npm-14.2.4-37fb4e5b51-3b858cfec2.zip/node_modules/next/dist/client/components/render-from-template-context.js");
